import { api } from '../axios/AxiosInstances';
import axios from "axios"
const config = {
    headers:{
      "X-API-KEY": "0c5a8da8-ed5b-4407-aac8-e2b03d998ae0"
    }
  }
function getItemByItemid(itemId) {
    var url = (localStorage.getItem("apiType") == "testnet") ? "https://testnet-api.rarible.org/v0.1/" : "https://api.rarible.org/v0.1/"
    console.log(itemId);
    return new Promise((resolve, reject) => {
        axios.get(url + "items/"+itemId.id, config)        
            .then((response)=>{
                if(response ){
                    // console.log("3",response.data)
                    resolve(response.data);
                }
            })
            .catch((error)=>{
                console.log(error);
                reject(error);
            })
    })
}

function getAllCollections(collection) {
    // console.log(collection);
    var url = (localStorage.getItem("apiType") == "testnet") ? "https://testnet-api.rarible.org/v0.1/" : "https://api.rarible.org/v0.1/"
    return new Promise((resolve, reject) => {
        if(collection.continuation){
            url = url + "collections/all?size=100&continuation="+collection.continuation
        }else{
            url = url + "collections/all?size=100"
        }
        axios.get(url, config)        
        .then(async(response)=>{
            if(response && response.data && response.data.collections){
                // console.log(response.data.collections);
                await Promise.all(response.data.collections.map(async (item) => {
                  if(item.meta && item.meta.content && item.meta.content.length > 0 && item.meta.content[0].url){
                    // console.log("_________ITEM______________",item);
                    const contents = await getItemsByCollection(item)
                    // console.log(contents);
                    if(contents.items.length !== 0){
                      return item
                    }else{
                      return []
                    }
                  }else{
                    return []
                  }
                })).then((values) => {
                    // console.log("5 getCollectionById___",values); // [3, 1337, "foo"]
                    response.data["items"] = values
                    resolve(response.data);
                  });
                // console.log("2",response.data)
                resolve(response.data);
            }
        })
        .catch((error)=>{
            reject(error);
        })      
            // .then((response)=>{
            //     console.log(response);
            //     if(response && response.data && response.data.collections){
            //         console.log(response.data)
            //         resolve(response.data);
            //     }
            // })
            // .catch((error)=>{
            //     reject(error);
            // })
    })
}

function getOwnershipByItemid(itemId) {
    var url = (localStorage.getItem("apiType") == "testnet") ? "https://testnet-api.rarible.org/v0.1/" : "https://api.rarible.org/v0.1/"
    // console.log(itemId);
    return new Promise((resolve, reject) => {
        axios.get(url + "ownerships/byItem?itemId="+itemId.id, config)        
            .then((response)=>{
                if(response ){
                    // console.log("3",response.data)
                    resolve(response.data);
                }
            })
            .catch((error)=>{
                console.log(error);
                reject(error);
            })
    })
}

function getCollectionById(collectionId) {
    var url = (localStorage.getItem("apiType") == "testnet") ? "https://testnet-api.rarible.org/v0.1/" : "https://api.rarible.org/v0.1/"
    return new Promise((resolve, reject) => {
        axios.get(url + "collections/"+collectionId.id, config)        
            .then((response)=>{
                if(response ){
                    // console.log("3",response.data)
                    resolve(response.data);
                }
            })
            .catch((error)=>{
                console.log(error);
                resolve(error);
            })
    })
}

function getItemsByCollection(collection) {
    // console.log(collection);
    var url = (localStorage.getItem("apiType") == "testnet") ? "https://testnet-api.rarible.org/v0.1/" : "https://api.rarible.org/v0.1/"
    return new Promise((resolve, reject) => {
        if(collection.continuation){
            url = url + "items/byCollection?collection="+collection.id+ "&continuation="+collection.continuation
        }else{
            url = url + "items/byCollection?collection="+collection.id
        }
        axios.get(url, config)        
            .then((response)=>{
                
                if(response && response.data && response.data.items){
                    // console.log(response.data)
                    resolve(response.data);
                }
            })
            .catch((error)=>{
                reject(error);
            })
    })
}

function getTrendingCollections(data) {
    var url = (localStorage.getItem("apiType") == "testnet") ? "https://testnet-api.rarible.org/v0.1/" : "https://api.rarible.org/v0.1/"
    return new Promise((resolve, reject) => {
        // console.log(data);
        if(data.continuation){
            url += "data/rankings/collections/volume?blockchain=ETHEREUM&period=D7&sort=VOLUME_USD_DESC&continuation="+data.continuation
        }else{
            url += "data/rankings/collections/volume?blockchain=ETHEREUM&period=D7&sort=VOLUME_USD_DESC&limit=25"
        }
        axios.get(url, config)        
            .then(async(response)=>{
                if(response.data ){
                    // console.log(response.data);
                    await Promise.all(response.data.result.map(async (item) => {
                      const contents = await getCollectionById(item)
                    //   console.log(contents);
                      return contents
                    })).then((values) => {
                        response.data["items"] = values
                        // console.log("5 trending",response); // [3, 1337, "foo"]
                        resolve(response.data);

                        // resolve(values);
                      });
                    // console.log("2",response.data)
                    resolve(response);
                }
            })
            .catch((error)=>{
                reject(error);
            })
    })
}



function getCollectionsLeaderboard(data) {
    var url = (localStorage.getItem("apiType") == "testnet") ? "https://testnet-api.rarible.org/v0.1/" : "https://api.rarible.org/v0.1/"
    return new Promise((resolve, reject) => {
        if(data.offset){
            url += "data/leaderboards/collections?offset="+data.offset
        }else{
            url += "data/leaderboards/collections?limit=20"
        }
        axios.get(url, config)        
            .then(async(response)=>{
                if(response.data ){
                    await Promise.all(response.data.map(async (item) => {
                      const contents = await getCollectionById(item)
                      return contents
                    })).then((values) => {
                        console.log("5",values); // [3, 1337, "foo"]
                        resolve(values);
                      });
                    console.log("9",response.data)
                    // resolve(response);
                }
            })
            .catch((error)=>{
                reject(error);
            })
    })
}

function getBuyerLeaderboard(paginationConfig) {
    var url = (localStorage.getItem("apiType") == "testnet") ? "https://testnet-api.rarible.org/v0.1/" : "https://api.rarible.org/v0.1/"
    return new Promise((resolve, reject) => {

        axios.get(url + "data/leaderboards/buyers", config)        
            .then(async(response)=>{
                if(response.data ){
                    await Promise.all(response.data.map(async (item) => {
                      const contents = await getCollectionById(item)
                      return contents
                    })).then((values) => {
                        console.log("5",values); // [3, 1337, "foo"]
                        resolve(values);
                      });;
                    // console.log("2",response.data)
                    // resolve(response);
                }
            })
            .catch((error)=>{
                reject(error);
            })
    })
}

function getUserItems(collectionId) {
    var url = (localStorage.getItem("apiType") == "testnet") ? "https://testnet-api.rarible.org/v0.1/" : "https://api.rarible.org/v0.1/"
    return new Promise((resolve, reject) => {
        console.log(localStorage.getItem('metaMaskAccount'));
        if(localStorage.getItem('metaMaskAccount')){
        axios.get(url + "items/byOwner?owner=ETHEREUM:"+localStorage.getItem('metaMaskAccount'), config)        
            .then((response)=>{
                if(response ){
                    console.log("3",response.data)
                    resolve(response.data.items);
                }
            })
            .catch((error)=>{
                console.log(error);
                reject(error);
            })
        }else{
            resolve([]);
        }
    })
}

function getAllModels(paginationConfig) {
    return new Promise((resolve, reject) => {
            api.post('/managefiles/filedatafilter', paginationConfig)
            .then((response)=>{
                resolve(response.data.result);
            })
            .catch((error)=>{
                reject(error);
            })
    })
}

function getModelsForPagination(paginationConfig,model) {
    return new Promise((resolve, reject) => {
            api.post('/managefiles/filedata', paginationConfig, {params:{model}})
            .then((response)=>{
                resolve(response);
            })
            .catch((error)=>{
                reject(error);
            })
    })
}

function addModel(modelObject) {
    return new Promise((resolve,reject) => {
        api.post('/managefiles',modelObject)
        .then((response)=>{
            resolve(response);
        })
        .catch((error)=>{
            reject(error);
        })
    })
}

function deleteModelFile(idsObject) {
    return new Promise((resolve,reject) => {
        api.put('managefiles/model',idsObject)
        .then((response)=>{
            resolve(response);
        })
        .catch((error)=>{
            reject(error);
        })
    })
}

function uploadFile(file) {
    let formData = new FormData();
    formData.append("file",file,file.name);
    return new Promise((resolve,reject) => {
        api.post('/upload',formData,{headers:{'Content-Type': 'multipart/form-data'}})
        .then((response)=>{
            resolve(response.data.result);
        })
        .catch((error)=>{
            reject(error);
        })
    })
}

function updateFile(id,fileDetails) {
    return new Promise((resolve,reject) => {
        api.put('/managefiles',fileDetails,{params:{id}})
        .then((response)=>{
            resolve(response);
        })
        .catch((error)=>{
            reject(error);
        })
    })
}

export { getAllModels,getModelsForPagination,addModel,deleteModelFile,uploadFile,updateFile,getItemsByCollection, getTrendingCollections, getCollectionById, getBuyerLeaderboard, getCollectionsLeaderboard, getUserItems, getItemByItemid, getOwnershipByItemid, getAllCollections }