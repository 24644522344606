import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import '../css/collectorsNFTs.css';
import placeHolder from '../images/myCollectionVideoPlaceholder.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getItemsByCollection } from '../api/FileManagementApi';
import { useParams,useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import {
    useFocusable,
    FocusContext
  } from "@noriginmedia/norigin-spatial-navigation";
import translations from './translations';

let isApiCallInProgress = false;
export function CollectorsNFTs() {
    let selectedLanguage = localStorage.getItem("selected-language");
    if(selectedLanguage == null || selectedLanguage == 'null') {
      selectedLanguage = 'en';
    }
    const translatedStrings = translations[selectedLanguage];

    useEffect(()=>{
        localStorage.setItem("shouldGoBack",true);
        localStorage.setItem("isDashboard",false);
        localStorage.setItem("navItemsSelectedState",JSON.stringify([false,true,false,false]));
        console.log("isDashboard",localStorage.getItem("isDashboard"));
        console.log("shouldGoBack",localStorage.getItem("shouldGoBack"));
    },[])
    if(!navigator.onLine){

        return(
          <Container style={{"textAlign":"center","marginTop":"25%","color":"wheat","fontSize":"x-large"}}>
            
              <span>{translatedStrings?.MAIN_MSG_NFT_NO_INTERNET}</span>
            
          </Container>
         );
       }
    
       else {
        return (
            <RowsWrapper/>
        )
       }
    
}

function RowsWrapper() {
    let selectedLanguage = localStorage.getItem("selected-language");
    if(selectedLanguage == null || selectedLanguage == 'null') {
      selectedLanguage = 'en';
    }
    const translatedStrings = translations[selectedLanguage];
    const filterItems = useCallback((item)=>{
        if(item.meta && item.meta.content && item.meta.content.length > 0 && item.meta.content[0].url) {
            return 1;
        }
        else {
            return 0;
        }
    },[])
    const collItems = useRef();
    const newArr = useRef([]);
    const { id, name } = useParams()
    const continuation = useRef();
    const [stateObject, setStateObject] = useState(0);
    const { ref, focusKey  } = useFocusable({
        focusKey: 'collection-items',
      });
    const fetchMoreData = useCallback(()=>{
        if(isApiCallInProgress) {
            return ;
        }
        else {
            isApiCallInProgress = true;
            (async function () {
                try {
                    let response;
                    response = await getItemsByCollection({id:id, continuation: continuation.current});
                    continuation.current = response.continuation;
                    let filteredItems = response.items.filter(filterItems);
                    collItems.current = [...collItems.current, ...filteredItems];
                    localStorage.setItem("items-cache",JSON.stringify(collItems.current));
                    localStorage.setItem("items-continuation-cache",JSON.stringify(continuation.current));
                    newArr.current = [];
                    let splicedArray = JSON.parse(JSON.stringify(collItems.current));
                    while(splicedArray.length){
                        newArr.current.push(splicedArray.splice(0,6));
                   } 
                   //make sure the last array in newArr has 6 items else fill with X
                   const desiredLength = 6;
                   //the filler is complex instead of a simple X because its useful as a Key below.
                   const filler = {element:{meta:{content:[{url:'X'}]}}};
                   if(newArr.current[newArr.current.length - 1].length < desiredLength) {
                    newArr.current[newArr.current.length - 1] = newArr.current[newArr.current.length - 1].concat(Array(desiredLength - newArr.current[newArr.current.length - 1].length).fill(filler));
                   } 
                   isApiCallInProgress = false;
                   setStateObject((stateObject) => stateObject + 1);
                }
                catch (err) {
                    isApiCallInProgress = false;
                    console.log('err', err);
                }
            })();
        }
    },[stateObject])  
    useEffect(()=>{
            (async function () {
                try {
                    let response;
                    let filteredItems;
                    let cacheditems = JSON.parse(localStorage.getItem("items-cache"));
                    let cachedcontinuation = JSON.parse(localStorage.getItem("items-continuation-cache"));
                    if(cacheditems==null || cacheditems=='null' || cachedcontinuation==null || cachedcontinuation=='null') {
                        console.log("Hello",cachedcontinuation,cacheditems);
                    response = await getItemsByCollection({id:id});
                    continuation.current = response.continuation;
                    filteredItems = response.items.filter(filterItems);
                    }
                    else {
                        continuation.current = cachedcontinuation;
                        filteredItems = cacheditems;
                    }
                    collItems.current = JSON.parse(JSON.stringify(filteredItems));
                    localStorage.setItem("items-cache",JSON.stringify(collItems.current));
                    localStorage.setItem("items-continuation-cache",JSON.stringify(continuation.current));
                    let splicedArray = JSON.parse(JSON.stringify(filteredItems));
                    newArr.current = [];
                    while(splicedArray.length){
                        newArr.current.push(splicedArray.splice(0,6));
                   } 
                   //make sure the last array in newArr has 6 items else fill with X
                   const desiredLength = 6;
                   //the filler is complex instead of a simple X because its useful as a Key below.
                   const filler = {element:{meta:{content:[{url:'X'}]}}};
                   if(newArr.current[newArr.current.length - 1].length < desiredLength) {
                    newArr.current[newArr.current.length - 1] = newArr.current[newArr.current.length - 1].concat(Array(desiredLength - newArr.current[newArr.current.length - 1].length).fill(filler));
                   } 
                   setWindowState(JSON.parse(localStorage.getItem("items-windowstate")));
                   setStateObject(stateObject+1);
                }
                catch (err) {
                    console.log('err', err);
                    if(err.code == "ERR_NETWORK"){
                        setStateObject("Network lost");
                    }else{
                        setStateObject("Server Error");
                    }
                }
            })();
    },[])

    const [windowState,setWindowState] = useState(0);
    var rowsCount = [0,1];
    if(newArr.current.length < 2) {
        if(newArr.current.length == 0) {//empty newArr
            rowsCount = [];
        }
        else {//newArr has only 1 array
            rowsCount = [0];
        }
    }

    if(stateObject == 0) {
        return (
            <FocusContext.Provider value={focusKey}>
        <Container style={{"textAlign": "center" , "marginTop": "25%"}}>
                <Spinner animation="border" role="status" variant="light">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        </FocusContext.Provider>
        )
    }

    if(stateObject == "Server Error") {
        return(<FocusContext.Provider value={focusKey}>
            <Container style={{"textAlign":"center","marginTop":"20%","color":"wheat","fontSize":"x-large"}}>
    
    <span>{translatedStrings?.MAIN_MSG_NFT_SERVER_ERROR}</span>
    
    </Container> 
            </FocusContext.Provider>)
    }
    else if(stateObject == "Network lost"){
        return (
            <FocusContext.Provider value={focusKey}>
            <Container style={{"textAlign":"center","marginTop":"9%","color":"wheat","fontSize":"x-large"}}>

            <span>{translatedStrings?.MAIN_MSG_NFT_NO_INTERNET}</span>

            </Container> 
            </FocusContext.Provider>
        );
    }

    else {
        return (
            <FocusContext.Provider value={focusKey}>
            <Container fluid>
                <Row className="header-row">
                    <Col style={{ display: 'flex' }}>
                        <div className="header-box"><img className="header-box-img" src={localStorage.getItem('collectionImg')}alt="image1"/></div>
                        <div className="header-title"> {localStorage.getItem('collectionId')}</div>
                    </Col>
                </Row>
                <Row>
                    <div id="CN-body" ref={ref}>
                        {rowsCount.map((index)=>{
                            return (
                                <ItemsRow fetchMoreData={fetchMoreData} noRows={rowsCount.length} rowItems={newArr.current[windowState+index]} rowIndex={index} windowState={windowState} setWindowState={setWindowState} newArrlastIndx={newArr.current.length - 1}/>
                            )
                        })}
                    </div>
                </Row>
            </Container>
            </FocusContext.Provider>
        )
    }
}

function ItemsRow({rowItems,rowIndex,windowState,setWindowState,newArrlastIndx,noRows,fetchMoreData}) {
    return(
        <div className="nft-row custom-left" >
            {rowItems.map((element,colIndex) => { 
            if((element.meta && element.meta.content && element.meta.content.length > 0 && element.meta.content[0].url) || element=='X' && element.meta.content[0].url != 'X'){
                return (
            <ItemCard
            fetchMoreData={fetchMoreData}
             element = {element}
            //  key={element.meta.content[0].url} 
             windowState={windowState} 
             setWindowState={setWindowState}
             rowIndex={rowIndex}
             colIndex = {colIndex}
             newArrlastIndx = {newArrlastIndx}
             noRows = {noRows}/>
            ) }})}
        </div>
    )
}

function ItemCard({element,windowState,setWindowState,rowIndex,colIndex,newArrlastIndx,noRows,fetchMoreData}) {
    let selectedLanguage = localStorage.getItem("selected-language");
  if(selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
    const translatedStrings = translations[selectedLanguage];
        const { ref, focused,focusSelf } = useFocusable({
            onArrowPress:(event) => {
                if(event == 'down' && rowIndex == 1) {
                    if(newArrlastIndx != windowState+1) {//windowState+1 = 2nd rows index in the newArr
                        setWindowState(windowState+1);
                    }
                    else {
                        fetchMoreData();
                    }
                }
                if(event == 'up' && rowIndex == 0 && windowState!=0) {
                    setWindowState(windowState-1);
                }
                if(event == 'down' && noRows == 1) {
                    // fetchMoreData();
                }
            },
            onEnterPress:() => {
                localStorage.setItem("items-windowstate",JSON.stringify(windowState));
                localStorage.setItem("items-rowIndex-cache",JSON.stringify(rowIndex));
                localStorage.setItem("items-colIndex-cache",JSON.stringify(colIndex));
                navigate('/nft/'+element.id);
            }
        });
        useEffect(()=> {
            let rIndex = JSON.parse(localStorage.getItem("items-rowIndex-cache"));
            let cIndex = JSON.parse(localStorage.getItem("items-colIndex-cache"));
            if(rowIndex == rIndex && colIndex == cIndex) {
                focusSelf();
            }
        },[])
        let navigate = useNavigate();
        return (
            <div ref={ref}
             style={{ display: "flex", flexDirection: "column" }} className={focused?"nft-card-focus":"nft-card"}>
                <div className="favouriteImage"><img key={element.meta.content[0].url} className="image" src={
                    element.meta.content.length == 4 ? element.meta.content[3].url :
                    element.meta.content.length == 3 ? element.meta.content[2].url :
                    element.meta.content.length == 2 ? element.meta.content[1].url :
                    element.meta.content[0].url
                } 
                    onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src=placeHolder;
                }} alt="image1"/></div>
                <div className="textAlignLeftName custom-center">{element.meta.name.length <= 20 ? element.meta.name : element.meta.name.slice(0,20)+"..."}</div>
                    <div style={{ display: "flex", flexDirection: "column" }} className="textAlignLeftFav">
                    {element.bestSellOrder ?  <div className="textAlignLeftFav-div1">{translatedStrings?.MAIN_NFT_PRICE} {(element.bestSellOrder&&element.bestSellOrder.makePrice) ? (element.bestSellOrder.makePrice.length > 6 ? element.bestSellOrder.makePrice.substring(0, 6) : element.bestSellOrder.makePrice) : ""} { (element.bestSellOrder&& element.bestSellOrder.take&& element.bestSellOrder.take.type) ? element.bestSellOrder.take.type["@type"] : ""}</div> : <div className="textAlignLeftFav-div1">{translatedStrings?.MAIN_NFT_PRICE} </div>}
                    {element.bestBidOrder ?  <div className="textAlignLeftFav-div1">{translatedStrings?.MAIN_NFT_HIGHEST_BID} { element.bestBidOrder.takePrice}</div> : <div className="textAlignLeftFav-div1">{translatedStrings?.MAIN_NFT_HIGHEST_BID}</div>}
                    </div>
                </div>
        )
}